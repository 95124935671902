import {Store} from 'react-notifications-component';

const successNotification = (message, type = 'success') => {
  Store.addNotification({
    title: type === 'default' ? 'Welcome' : type,
    message: message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeInRight'],
    animationOut: ['animated', 'fadeOutRight'],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true
    }
  });
};
const errorNotification = (error) => {
  const {response} = error;
  if (error.response) {
    if (response.status !== 401 && response.statusText) {
      if (response.status === 500) {
        Store.addNotification({
          title: `Server Error`,
          message: 'Something went Wrong!',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeInRight'],
          animationOut: ['animated', 'fadeOutRight'],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true
          }
        });
      } else {
        Store.addNotification({
          title: `Error with status code :${response.status} `,
          message: response.statusText,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeInRight'],
          animationOut: ['animated', 'fadeOutRight'],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true
          }
        });
      }
    }
  }
};

export {errorNotification, successNotification};
