import {useState, useRef} from 'react';
const useApi = (cb, ...params) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(undefined);
  const isMountedRef = useRef(null);
  return {
    isLoading,
    response,
    setResponse,
    isMountedRef,
    reset: () => {
      setResponse(undefined);
      setIsLoading(false);
    },
    fetch: async (reload = false, useLoading = true, ...p) => {
      try {
        if (!isMountedRef.current) isMountedRef.current = true;
        if ((!response || reload) && useLoading) setIsLoading(true);
        const data = await cb(...params, ...p);
        if (isMountedRef.current) {
          if (data) {
            setResponse(Array.isArray(data) ? [...data] : typeof data == 'string' ? data : {...data});
          } else setResponse();
        }
      } catch (e) {
        if (isMountedRef.current) setResponse();
      } finally {
        if (isMountedRef.current) setIsLoading(false);
      }
    }
  };
};

export default useApi;
