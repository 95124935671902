import "./style.scss";

const LoaderFull = ({ color = "#0D254F" }) => {
  return (
    <div className='loader-container'>
      <div className='text-center'>
        <div className='lds-ellipsis'>
          <div style={{ backgroundColor: color }}></div>
          <div style={{ backgroundColor: color }}></div>
          <div style={{ backgroundColor: color }}></div>
          <div style={{ backgroundColor: color }}></div>
        </div>
      </div>
    </div>
  );
};
export default LoaderFull;
