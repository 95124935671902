import api from "../api/apiInstance";

export const getData = async () => {
  const promises = [];
  try {
    promises.push(api.get("Welcome"));
    promises.push(api.get("About"));
    promises.push(api.get("Sections/GetAllActiveWithDetails"));
    promises.push(api.get("PartnerSection"));
    promises.push(api.get("Partners/GetActive"));
    promises.push(api.get("Contact"));
    promises.push(api.get("FooterSections/GetActive"));
    promises.push(api.get("SocialLinks/GetActive"));

    const data = await Promise.all(promises);
    const welcome = data[0].data;
    const about = data[1].data;
    const sections = data[2].data;
    const partner = data[3].data;
    const partners = data[4].data;
    const contact = data[5].data;
    const footer = data[6].data;
    const socialLinks = data[7].data;
    return {
      welcome,
      about,
      sections,
      partner,
      partners,
      contact,
      footer,
      socialLinks,
    };
  } catch (error) {
    throw error;
  }
};
