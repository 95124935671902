import api from "../api/apiInstance";

export const userLogin = async (username, password) => {
  try {
    const user = await api.get(
      `Users/Login?Username=${username}&password=${password}`
    );
    return user.data;
  } catch (error) {
    return null;
  }
};
export const getUsers = async () => {
  try {
    const users = await api.get("Users");
    return users.data;
  } catch (error) {}
};

export const getUserByUsername = async (username) => {
  try {
    const user = await api.get("Users/" + username);
    return user.data;
  } catch (error) {}
};

export const createUser = async (user) => {
  try {
    const response = await api.post(`Users`, user);
    return response.data;
  } catch (error) {}
};

export const updateUserDetails = async (username, user) => {
  try {
    const response = await api.put(`Users/${username}`, user);
    return response.data;
  } catch (error) {}
};
