import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import { LoaderFull } from "./components/Utils";
import { useApi } from "./hooks";
import { getData } from "./services/general";

function App() {
  const ComingSoonContainer = lazy(() =>
    import("./containers/ComingSoonContainer")
  );
  const Home = lazy(() => import("./containers/HomeContainer"));
  const ServiceDetails = lazy(() => import("./containers/ServiceContainer"));
  const Login = lazy(() => import("./containers/LoginContainer"));
  const WelcomeSectionEdit = lazy(() =>
    import("./components/Admin/WelcomeSectionEdit")
  );
  const AboutSectionEdit = lazy(() =>
    import("./components/Admin/AboutSectionEdit")
  );
  const PartnerSectionEdit = lazy(() =>
    import("./components/Admin/PartnerSectionEdit")
  );
  const SectionListEdit = lazy(() =>
    import("./components/Admin/SectionListEdit")
  );
  const ServiceEdit = lazy(() => import("./components/Admin/ServiceEdit"));
  const ServiceCreate = lazy(() => import("./components/Admin/ServiceCreate"));
  const ServiceSectionEdit = lazy(() =>
    import("./components/Admin/ServiceSectionEdit")
  );
  const ServiceSectionCreate = lazy(() =>
    import("./components/Admin/ServiceSectionCreate")
  );
  const ContactSectionEdit = lazy(() =>
    import("./components/Admin/ContactSectionEdit")
  );
  const PartnersList = lazy(() => import("./components/Admin/PartnersList"));
  const PartnerEdit = lazy(() => import("./components/Admin/PartnerEdit"));
  const PartnerCreate = lazy(() => import("./components/Admin/PartnerCreate"));
  const FooterListEdit = lazy(() =>
    import("./components/Admin/FooterListEdit")
  );
  const FooterSectionEdit = lazy(() =>
    import("./components/Admin/FooterSectionEdit")
  );
  const FooterSectionCreate = lazy(() =>
    import("./components/Admin/FooterSectionCreate")
  );
  const SocialLinksList = lazy(() =>
    import("./components/Admin/SocialLinksList")
  );
  const SocialLinkEdit = lazy(() =>
    import("./components/Admin/SocialLinkEdit")
  );
  const SocialLinkCreate = lazy(() =>
    import("./components/Admin/SocialLinkCreate")
  );
  const UsersList = lazy(() => import("./components/Admin/UsersList"));
  const UserEdit = lazy(() => import("./components/Admin/UserEdit"));
  const UserCreate = lazy(() => import("./components/Admin/UserCreate"));
  const ContactFormView = lazy(() =>
    import("./components/Admin/ContactFormView")
  );
  const ContactFormsList = lazy(() =>
    import("./components/Admin/ContactFormsList")
  );

  const data = useApi(getData);
  const Page404 = lazy(() => import("./containers/Page404"));

  useEffect(() => {
    data.fetch();
    return () => {
      data.isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (data.isLoading) return <LoaderFull />;

  return (
    <Suspense fallback={<LoaderFull />}>
      <Routes>
        <Route exact path='/' element={<Home data={data.response} />} />
        <Route exact path='/preview' element={<Home data={data.response} />} />
        <Route
          exact
          path='/services/:friendlyUrl'
          element={<ServiceDetails />}
        />
        <Route exact path='/admin' element={<Login />} />
        <Route exact path='/admin/welcomesection' element={<PrivateRoute />}>
          <Route
            path='/admin/welcomesection'
            element={<WelcomeSectionEdit />}
          />
        </Route>
        <Route exact path='/admin/aboutsection' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/aboutsection'
            element={<AboutSectionEdit />}
          />
        </Route>
        <Route exact path='/admin/partnersection' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/partnersection'
            element={<PartnerSectionEdit />}
          />
        </Route>
        <Route exact path='/admin/contactsection' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/contactsection'
            element={<ContactSectionEdit />}
          />
        </Route>
        <Route
          exact
          path='/admin/servicessections/:id'
          element={<PrivateRoute />}
        >
          <Route
            exact
            path='/admin/servicessections/:id'
            element={<ServiceSectionEdit />}
          />
        </Route>
        <Route
          exact
          path='/admin/servicessections/create'
          element={<PrivateRoute />}
        >
          <Route
            exact
            path='/admin/servicessections/create'
            element={<ServiceSectionCreate />}
          />
        </Route>
        <Route exact path='/admin/servicessections' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/servicessections'
            element={<SectionListEdit />}
          />
        </Route>
        <Route exact path='/admin/service/create' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/service/create'
            element={<ServiceCreate />}
          />
        </Route>
        <Route exact path='/admin/service/:id' element={<PrivateRoute />}>
          <Route exact path='/admin/service/:id' element={<ServiceEdit />} />
        </Route>
        <Route exact path='/admin/partnersList' element={<PrivateRoute />}>
          <Route exact path='/admin/partnersList' element={<PartnersList />} />
        </Route>
        <Route exact path='/admin/partner/create' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/partner/create'
            element={<PartnerCreate />}
          />
        </Route>
        <Route exact path='/admin/partner/:id' element={<PrivateRoute />}>
          <Route exact path='/admin/partner/:id' element={<PartnerEdit />} />
        </Route>
        <Route exact path='/admin/footerSection' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/footerSection'
            element={<FooterListEdit />}
          />
        </Route>
        <Route
          exact
          path='/admin/footerSection/create'
          element={<PrivateRoute />}
        >
          <Route
            exact
            path='/admin/footerSection/create'
            element={<FooterSectionCreate />}
          />
        </Route>
        <Route exact path='/admin/footerSection/:id' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/footerSection/:id'
            element={<FooterSectionEdit />}
          />
        </Route>
        <Route exact path='/admin/sociallinks' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/sociallinks'
            element={<SocialLinksList />}
          />
        </Route>
        <Route exact path='/admin/socialLink/create' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/socialLink/create'
            element={<SocialLinkCreate />}
          />
        </Route>
        <Route exact path='/admin/socialLink/:id' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/socialLink/:id'
            element={<SocialLinkEdit />}
          />
        </Route>
        <Route exact path='/admin/usersList' element={<PrivateRoute />}>
          <Route exact path='/admin/usersList' element={<UsersList />} />
        </Route>
        <Route exact path='/admin/user/create' element={<PrivateRoute />}>
          <Route exact path='/admin/user/create' element={<UserCreate />} />
        </Route>
        <Route exact path='/admin/user/:username' element={<PrivateRoute />}>
          <Route exact path='/admin/user/:username' element={<UserEdit />} />
        </Route>
        <Route exact path='/admin/contactFormsList' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/contactFormsList'
            element={<ContactFormsList />}
          />
        </Route>
        <Route exact path='/admin/contactForm/:id' element={<PrivateRoute />}>
          <Route
            exact
            path='/admin/contactForm/:id'
            element={<ContactFormView />}
          />
        </Route>
        <Route path='*' element={<Page404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
